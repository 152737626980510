import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const AboutPage = ({ active = `about` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | About Us" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-mylightorange"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-white text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        About My Work and Well-Being Consulting
      </p>
      <StaticImage
        src="../images/image-2.jpg"
        alt="Decorative image of a healthy workplace team"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">About Us</h3>
        <h2 className="text-4xl md:text-5xl mb-12">
          My Work and Well-Being Consulting
        </h2>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          My Work and Well-Being Consulting will work with organizational
          leaders to foster, promote, and maintain a healthy workplace through
          strategic well-being strategies and processes. Our Team partners with
          organizational leaders on all Well-Being and HR Services, Policies,
          Procedures, and Processes.
        </p>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          Well-being, Resilience, and Productivity-Driven Solutions: Boost Your
          Employees’ Well-Being, build resilience, maximize performance, and
          make your organization a great place to work.
        </p>
        <p className="text-xl md:text-2xl mb-12 text-gray-800">
          We measure and manage employees' health and well-being. Our approach
          is independent and is based on established scientific methods that
          have been used extensively within the industry and in academia.
        </p>
        <div className="ml-8">
          <h3 className="text-myorange text-2xl md:text-3xl mt-8 mb-2">
            Our Vision
          </h3>
          <p className="text-lg text-gray-800 max-w-lg">
            We partner with our clients towards client-focused outcomes to boost
            your workplace health and well-being and increase your productivity,
            potential and organizational sustainability.
          </p>
          <h3 className="text-myred text-2xl md:text-3xl mt-8 mb-2">
            Our Mission
          </h3>
          <p className="text-lg text-gray-800 max-w-lg">
            We provide leadership strategic approaches, research, coaching and
            consulting services in workplace health and well-being. Our programs
            and services are responsive, effective and efficient and contribute
            to a high-performing workforce and to the success of your
            organizational strategic goals.
          </p>
        </div>
      </div>
    </section>
    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/image-board.jpg"
          alt="Decorative image depicting the word motivation"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default AboutPage
